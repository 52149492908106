.discard-modal{
    position: fixed;
    height: 320px;
    width: 670px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;    
    border-radius: 5px;
}

@media screen and (max-width: 540px) {

    .discard-modal{
        height: 320px;
        width: 90%;
    }
}

.discard-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(89, 89, 89, 0.8);
    z-index: 0;
}



.modalComponent-root{
    width: 0%;
    height: 0%;
}
.discard-modal-title{
    margin-left: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.discard-modal-description-wrapper{
    font-size: 16px;
    font-weight: 600;
    padding: 40px 20px 40px 20px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .discard-modal-description-title{
        font-size: 30px;
    }
    .discard-modal-description-body{
        margin-top: 10px;
    }
    .discard-modal-description-body-p{
        margin:0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    @media screen and (max-width: 540px) {
        .discard-modal-description-title{
            font-size: 20px;
        }
     }
}

@media screen and (max-width: 540px) {

   .discard-modal-description-wrapper{
       padding: 5px 20px 20px 20px;
   }
}

.discard-modal-header{
    width: 100%;
    height: 70px;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal-block-image{
    width: 20px;
    margin-right: 20px;
    cursor: pointer;
}
.discard-modal-title-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.discard-modal-bottom-block{
    border-top: 1px solid lightgray;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.discard-modal-button-cancel{
    max-width: 140px;
    min-width: 140px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
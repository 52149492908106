@import '../../../Common/styles/colors';
@import '../../../Common/styles/align';

.communication-preferences-wrapper{
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: flex-start;
}

.communication-preferences-msg-type-block{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: $marginLeftSubDetail;
}

.commun-pre-msg-bl{
    margin-top: 15px;
    .commun-pre-title{
        font-size: 15px;
        font-weight: 600;
    }
}

.commun-pre-check-box{
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .checkbox-block-item{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .checkbox-block-item-span{
        margin-left: 5px;
        font-size: 15px;
    }
}

.commun-pre-lang-block{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;

    .commun-pre-lang-title{
        .commun-pre-lang-title-text{
            font-size: 15px;
            font-weight: 600;
        }
    }

    .commun-pre-lang-button{
       margin-top: 10px;
    }
}

.commun-pre-lang-dropdown-button{
    width: 120px;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
}

// .ant-btn .ant-dropdown-trigger .commun-pre-lang-dropdown{
//     width: 200px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }



.checkbox-wrapper{
    display: flex;
    flex-direction: column;
}

.checkbox-title{
    display: flex;
}

.checkbox-block{
    margin-top: 15px;
    display: flex;
    flex-direction: column;

    .checkbox-block-item{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .checkbox-block-item input {
        margin-right: 7px;
    }

    .checkbox-block-item span {
        margin-right: 7px;
    }

    .checkbox-block-image{
        width: 20px;
    }
}

.checkbox-block-tooltip-wrapper{
    display: flex;
   flex-direction: column;
   @media screen and (max-width: 320px) {
    padding: 2px;
}
   padding: 10px;
   width: auto !important;
   max-width: 500px !important;
   min-width: 100px !important;
}

.checkbox-block-tooltip{
    box-shadow: 0 0 5px rgb(160, 157, 157) !important;
    opacity: 1 !important;
    width: auto !important;
    max-width: 550px !important;
    min-width: 100px !important;
    @media screen and (max-width: 500px) {
    max-height: 330px!important;
}
}

.checkbox-block-tooltip-share-alerts-title{
    font-weight: 700;
    @media screen and (max-width: 500px) {
        font-size:13px;
        margin-top: 0;
    }
    font-size:18px ;
}

.checkbox-block-tooltip-by-default-title{
    font-weight: 700;
    font-size: 18px;
    @media screen and (max-width: 500px) {
        font-size:12px;
        margin-top: 0;
    }
    margin-top: 10px;
}

.checkbox-block-tooltip-bottom-span{
    font-size: 16px;
    @media screen and (max-width: 500px) {
        font-size:13px;
        margin: 0 0 0 0;
    }
    margin: 10px 0 0 0;
}

.checkbox-block-tooltip-span-with-dots{
    margin-left: 10px;
    @media screen and (max-width: 500px) {
        font-size:12px;
    }
    font-size: 16px;
}

.checkbox-tooltip{
    transition: none !important;
    background-color: red !important;
}


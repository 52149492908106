.subscribeBlock-root{
    display: flex;
    flex-direction: column;
}
.subscribeBlock-button{
    max-width: 110px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
  //  background-color: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.subscribeBlock-root-have-subscription{
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
}
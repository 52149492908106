@import '/../../../Common/styles/colors';
@import '/../../../Common/styles/align';

.shared-alerts-preference-alert{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}




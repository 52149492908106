.check-subscription-modal{
    position: fixed;
    height: 320px;
    width: 670px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;    
    border-radius: 5px;
}

@media screen and (max-width: 540px) {

    .check-subscription-modal{
        height: 320px;
        width: 90%;
    }
}

.discard-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(89, 89, 89, 0.8);
    z-index: 10000;
}
.modalComponent-root{
    width: 0%;
    height: 0%;
}
.check-subscription-modal-title{
    margin-left: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.check-subscription-modal-description-wrapper{
    padding: 25px 20px 40px 20px;
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;

    .check-subscription-modal-description-title{
        font-size: 15px;
        margin-bottom: 10px;
    }
    .check-subscription-modal-input{
        width: 100%;
        height: 35px;
        border-radius: 2px;
        border: 1px solid #E0E0E0;
        outline:none;
        padding: 3px 3px 3px 10px;
    }
    .check-subscription-modal-input-error{
        @extend .check-subscription-modal-input;
        border: 1px solid #e55858;
    }
}

.check-subscription-modal-header{
    width: 100%;
    height: 70px;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal-block-image{
    width: 20px;
    margin-right: 20px;
    cursor: pointer;
}
.check-subscription-modal-title-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.check-subscription-modal-bottom-block{
    border-top: 1px solid lightgray;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.check-subscription-modal-button{
    max-width: 140px;
    min-width: 140px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0px 10px 0px 10px;
}

@media screen and (max-width: 540px) {

    .check-subscription-modal-button{
        max-width: 125px;
        min-width: 125px;
    }
}


@import '../Common/styles/colors';

.unsubscribepage-wrapper{
    min-width: 600px;
    background-color: white;
}

.unsubscribepage-company-title-block{
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .unsubscribepage-company-title{
        font-size: 22px;
        margin-left: 10px;
    }
}

.unsubscribepage-detail-wrapper{
    margin: 10px;
    border: 1px solid $defaultBorderColor;
    display: flex;
    flex-direction: column;   
    justify-content: left;
}
.subscription-detail-block-top{
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
}
.subscription-detail-block-bottom{
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    font-size: 16px;
}
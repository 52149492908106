@import '../Common/styles/colors';

.main-wrapper{
    min-width: 600px;
    background-color: white;
}

.main-company-title-block{
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .main-company-title{
        font-size: 22px;
        margin-left: 10px;
    }

}

.subscription-detail-wrapper{
    margin: 10px;
    // width: 100%;
    // height: 100vh;
    border: 1px solid $defaultBorderColor;
}

.subscription-detail-block{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    .subscription-detail-title{
        font-size: 22px;
        margin-left: 20px;
    }
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: black !important;
    border-color: black !important;
  }

  .main-company-subscription-confirmed-block{
      background-color: #EBFBD9;
      margin: 10px;
      display: flex;
      justify-content: flex-start;
      border-radius: 5px;
      .main-company-subscription-confirmed-title{
        font-size: 16px;
        margin: 8px;
        font-weight: 500;
      }
  }
$defaultBorderColor:#e0e0e0;
$enableDeleteLinkColor: #666666;
$titleTableEngArColor: #000000;
$primaryBlueButtonColor: #1890ff;
$primaryBlueButtonColorHover:#1890ff;

$darkBackground: #0b1928;

$greyTextColor: #000000;
$greenColor: #21CE99;
$purpleColor: #D251F7;
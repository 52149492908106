@import '/../../../../../Common/styles/colors';

.alert-cell-wrapper{
    display: flex;
    min-height: 67px;
    grid-gap: 0px;
    background-color: white;
    border-bottom: 1px solid $defaultBorderColor;
    width: 100%;
    height: 80px;
    border: 1px solid $defaultBorderColor;
    max-height: 130px;
}
.alert-cell-title-text-wrapper{
    display: flex;
    width: 100%;
    margin-left: 10px;
    align-items: center;
}

.alert-cell-checkbox-block{
    grid-area: checkbox;
    margin-left: 10px;
    margin-top: 13px;
}

.alert-cell-info-block{
    color:$titleTableEngArColor;
    display: flex;
    flex-direction: row;
    margin: 5px 0 0 35px;
    text-align: start;
}

.alert-cell-left-block{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 70%;
}

.alert-cell-right-block{
    display: flex;
    align-content: center;
    justify-content: center;
    display: flex;
    width: 30%;
    flex-direction: column;

    justify-self: end;
    color:$titleTableEngArColor;
}
.alert-cell-data-last-updated{
    text-align: end;
  //  margin-right: 5px;
}
.alert-cell-data-published{
    text-align: end;
}

@mixin text-styles($color) {
    font-size: 15px;
    color: $color;
    font-family: sans-serif;
}
.alert-cell-title-text{        
   font-size: 17px;
   font-weight: 700;
   width: 100%;
   align-self: center;
   justify-self: center;
   text-align: left;
   margin-left: 10px;
}

.alert-cell-title-text-trash{
    color: $primaryBlueButtonColor;
}

.alert-cell-title-block{
    grid-area: title;
    justify-self: start;
    margin-right: 80px;
}


.alert-cell-input-wrapper{
    display: flex;
    justify-content: flex-end;
    .alert-cell-input{
      //  
        width: 150px;
    }
}
.alert-cell-span-percentage{
    line-height: 30px;
    width: 40px;
    align-content: center;
    border-bottom: 1px solid $defaultBorderColor;
    border-right: 1px solid $defaultBorderColor;
    border-top: 1px solid $defaultBorderColor;
    margin-right: 20px;
   text-align: center;
}
.alert-cell-desciption-text{
    text-align: left;

}